import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { mainColor } from '@/utils/constants';

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ '@/scenes/Home'));
const Services = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Services'));
const Portfolio = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Portfolio'));
const Company = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Company'));
const Cooperation = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Cooperation'));
const Contacts = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Contacts'));
const Articles = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Articles'));
const Article = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Articles/Article'));

const Policy = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/Policy'));
const NotFound = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/NotFound'));
const Plug = React.lazy(() => import(/* webpackChunkName: "plug" */ '@/scenes/Plug'));

const Admin = React.lazy(() => import(/* webpackChunkName: "admin" */ '@/scenes/AdminPanel/Login'));

const Router: React.FC = () => {
  // const params = useParams();
  // console.log(params);

  return (
    <Suspense
      fallback={
        <CircularProgress
          color="primary"
          sx={{
            position: 'absolute',
            top: '20%',
            left: '49%',
            color: mainColor,
          }}
        />
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:name" element={<Services />} />
        <Route path="/services/:name/:service" element={<Services />} />
        <Route path="/rent" element={<Plug />} />
        <Route path="/sites" element={<Plug />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/company" element={<Company />} />
        <Route path="/cooperation" element={<Cooperation />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:category" element={<Articles />} />
        <Route path="/articles/:category/:name" element={<Article />} />
        <Route path="/contacts" element={<Contacts />} />

        <Route path="/policy" element={<Policy />} />

        <Route path="/admin/login" element={<Admin />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
