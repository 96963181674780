import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Scene: React.FC<Props> = ({ children }) => {
  // const params = useParams();
  // console.log(params);

  return <main>{children}</main>;
};

export default Scene;
