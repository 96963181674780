import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Alert } from '@mui/material';
//
import { adminExit, changeLoginInfo, getLoginInfo, initAdmin } from '@/store/admin/slicer';
import { selectAdmin } from '@/store/admin/selectors';
import { mainFailure, mainLoaded } from '@/store/main/selectors';
import { getArticlesList, getPortfolioList } from '@/store/main/slicer';
import { menuLinks, menuServices } from '@/utils/constants';
import { getMenuLinks, setTextOpacity, setHeaderScroll, formatVisitDate } from '@/utils/methods';
import { CloseBtn, MenuBtn, SearchBtn, TgBtn, WaBtn } from '@/assets/images/Icons';
import FeedbackModal from '@/components/FeedbackModal';

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  //const params = useParams();
  const menuEl = useRef<HTMLDivElement>(null);
  const loadRef = useRef<boolean>(true);
  const { authed, currAdmin, loginInfo } = useSelector(selectAdmin, shallowEqual);
  const loaded = useSelector(mainLoaded, shallowEqual);
  const error = useSelector(mainFailure, shallowEqual);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuServ, setShowMenuServ] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const pathname = location.pathname;
  const regAdminPath = pathname.match(/\/admin\/[a-z]+/);

  const showListServices = (show?: boolean) => {
    setShowMenuServ(show);
    const ls = menuEl.current.querySelector('.listServices') as HTMLDivElement;

    if (!show) {
      ls.style.height = '0';
      ls.style.opacity = '0';
      // setTimeout(() => {
      //   ls.style.display = 'none';
      // }, 170);
    } else {
      //ls.style.display = 'block';
      setTimeout(() => {
        ls.style.opacity = '1';
        ls.style.height = '155px';
      }, 0);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', setHeaderScroll);
    setTextOpacity(['#header-opacity-0', '#footer-opacity-0']);

    dispatch(getPortfolioList());
    dispatch(getArticlesList());

    return () => {
      document.removeEventListener('scroll', setHeaderScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    //const headerEl = document.getElementById('scroll-hidden');
    // if (location.pathname === '/') headerEl.classList.remove('hidden');
    // else headerEl.classList.add('hidden');

    getMenuLinks(location.pathname, menuLinks);
    getMenuLinks(location.pathname, menuServices);

    //console.log(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const reg = pathname.match(/\/services/);
    if (showMenu && !!reg) {
      showListServices(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMenu]);

  //
  useEffect(() => {
    if (authed && !!currAdmin?.id && loadRef.current) {
      dispatch(initAdmin(currAdmin.id));
      dispatch(getLoginInfo());
      setTimeout(() => dispatch(changeLoginInfo(currAdmin.id)), 100);
    }

    loadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, currAdmin?.id]);

  // Actions

  const handleToggleMenu = (open: boolean, path?: string) => (e: React.MouseEvent) => {
    const menu = menuEl.current;
    const services = '/services';

    if (!!menu && path !== services) {
      menu.style.animation = 'menuClose .4s ease-in-out both';
      setTimeout(() => {
        setShowMenu(open);
        setShowMenuServ(false);
      }, 400);
      return;
    }

    if (!!menu && path === services) {
      e.preventDefault();
      if (!showMenuServ) showListServices(true);
      else showListServices(false);
    }

    if (!menu) setShowMenu(open);
  };

  const handleClick = () => {
    if (!openModal) setOpenModal(true);
    else setOpenModal(false);
  };

  const handleExit = () => {
    dispatch(adminExit());
  };

  //

  const setLinkClass = (path: string) => {
    const regServices = pathname.match(/\/services/);
    const regServicesPath = path.match(/\/services/);
    //
    const regArticles = pathname.match(/\/articles/);
    const regArticlesPath = path.match(/\/articles/);
    // const cutLocPath = pathname.slice(0, 9);
    // const cutPath = path.slice(0, 9);
    const listActiveClass = 'list__item list__item_active';

    if (pathname === path) return listActiveClass;
    //else if (cutLocPath === cutPath && !!reg) return 'list__item list__item_active';
    else if (!!regServices && !!regServicesPath) return listActiveClass;
    else if (!!regArticles && !!regArticlesPath) return listActiveClass;
    else return 'list__item';
  };

  const setServiceClass = (path: string) => {
    const newReg = new RegExp(path);
    const regPath = pathname.match(newReg);
    //const regPath = `/services/${params?.name}`;

    if (pathname === path) return 'listServices__link listServices__link_active';
    else if (!!regPath) return 'listServices__link listServices__link_active';
    else return 'listServices__link';
  };

  const createMenuEl = (name: string) => {
    return (
      <nav className={name}>
        <ul className="list">
          {menuLinks.map((link, idx) => (
            <React.Fragment key={idx}>
              {link.show && (
                <li className={setLinkClass(link.path)}>
                  <Link
                    to={link.path}
                    className="link"
                    onClick={handleToggleMenu(false, link.path)}
                  >
                    {link.name}
                  </Link>

                  {link.path === '/services' && (
                    <div id="menu-services" className="servicesWrapper">
                      <ul className="listServices">
                        {menuServices.map((service, id) => (
                          <li key={id} className="listServices__item">
                            <Link
                              to={service.path}
                              className={setServiceClass(service.path)}
                              onClick={handleToggleMenu(false)}
                            >
                              {service.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              )}

              {/* <li className={setLinkClass(link.path)} key={idx}>
                {link.path !== '/' && (
                  <Link to={link.path} className="link" onClick={handleToggleMenu(false, link.path)}>
                    {link.name}
                  </Link>
                )}

                {link.path === '/services' && (
                  <div id="menu-services" className="servicesWrapper">
                    <ul className="listServices">
                      {menuServices.map((service, id) => (
                        <li key={id} className="listServices__item">
                          <Link
                            to={service.path}
                            className={setServiceClass(service.path)}
                            onClick={handleToggleMenu(false)}
                          >
                            {service.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li> */}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    );
  };

  //

  return (
    <header id="header-scroll">
      {authed && !!currAdmin?.id && (
        <div id="header-opacity-0" className="headerAdmin container mainTransition textOpacity">
          <p className="adminInfo">
            Вы зашли как администратор,
            <span> вам доступен режим редактирования сайта</span>
          </p>

          <p className="adminId">
            ID последнего входа:
            <span> {loginInfo?.id} /</span>
            <span> {formatVisitDate(loginInfo?.date)}</span>
          </p>

          <div className="adminAction">
            <p className="text">
              Ваш ID:
              <span> {currAdmin?.id}</span>
            </p>

            <button type="button" className="btn mainBtn" onClick={handleExit}>
              ВЫЙТИ
            </button>
          </div>
        </div>
      )}

      {!regAdminPath && (
        <div id="header-opacity-0" className="container mainTransition textOpacity">
          <div className="headerInfoWrapper">
            <Link to="/" className="linkLogo" onClick={handleToggleMenu(false)}>
              {/* <div className="imageWrap">
                <img src="/images/icons/bf_logo.svg" alt="logo" />
              </div> */}
              Beffect
            </Link>

            <div className="searchWrapper">
              <input type="search" name="search" id="header-search" className="field" disabled />
              {SearchBtn}
            </div>

            <div className="headerInfo">
              <div className="feedback">
                <a
                  href="https://wa.me/79950117705"
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn linkTel"
                  aria-label="whatsapp"
                >
                  {WaBtn}
                </a>
                <a
                  href="https://t.me/beffect_group"
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn linkTel"
                  aria-label="telegram"
                >
                  {TgBtn}
                </a>

                <button className="btnCall mainBtn" onClick={handleClick}>
                  Обсудить проект
                </button>
              </div>

              <button
                className="btnMenu mainBtn"
                aria-label="open-menu"
                onClick={handleToggleMenu(true)}
              >
                {MenuBtn}
              </button>
            </div>
          </div>

          {createMenuEl('menu')}

          {!!showMenu && (
            <div ref={menuEl} className="mobileMenuWrapper">
              <div className="closeWrapper">
                <button className="closeBtn mainBtn" onClick={handleToggleMenu(false)}>
                  {CloseBtn}
                </button>
              </div>

              <div className="mobileInfo">
                <a href="tel:+79950117705" className="linkTel">
                  8(995)011-77-05
                </a>
                <span className="text">Заявки и консультации</span>
              </div>

              <hr className="mobileDivider" />
              {createMenuEl('mobileMenu')}
            </div>
          )}
        </div>
      )}

      {openModal && (
        <FeedbackModal
          settings={{
            openModal: openModal,
            setOpenModal: setOpenModal,
          }}
          fullModal={false}
          shortModal={true}
        />
      )}

      {!!loaded && (
        <Alert severity="success" className="mainAlert">
          Данные отправлены
        </Alert>
      )}
      {!!error && (
        <Alert severity="error" className="mainAlert">
          Ошибка отправки. Попробуйте еще раз
        </Alert>
      )}
    </header>
  );
};

export default Header;
