// Common

// IT компания “Эффект Бабочки” — Digital агенство полного цикла, мы создаем и улучшаем присутствие компаний в сети интернет

//const companyName = 'Butterfly Effect';
const companyName = 'Beffect';

export const mainColor = '#0BC1F8';

export const modalStyle = {
  m: '10px',
  maxWidth: 'initial',
  maxHeight: 'calc(100% - 15px)',
  background: '#FFF',
};

// Menu

export const menuLinks = [
  {
    name: 'Главная',
    path: '/',
    show: false,
    title: 'Butterfly Effect — Профессиональные услуги программирования, дизайна и маркетинга',
    description:
      'IT компания “Эффект Бабочки” — предоставляет качественные услуги программирования, уникального дизайна и эффективного маркетинга. Узнайте, как мы можем помочь вашему бизнесу достичь успеха онлайн',
  },
  {
    name: 'Услуги',
    path: '/services',
    show: true,
    title: 'Наши услуги — ' + companyName,
    description: 'Наша IT компания предоставляет больший выбор услуг',
  },
  {
    name: 'Аренда',
    path: '/rent',
    show: true,
    title: 'Аренда IT проектов: Эффективные решения для вашего бизнеса — ' + companyName,
    description:
      'Арендуйте высококвалифицированные IT проекты у нас. Повысьте производительность и эффективность вашего бизнеса с нашими инновационными решениями',
  },
  {
    name: 'Сервисы',
    path: '/sites',
    show: true,
    title: 'Наши сервисы: Программирование, Дизайн, Маркетинг — ' + companyName,
    description:
      'Ознакомьтесь с нашими разнообразными сервисами: программирование, дизайн, маркетинг. Мы предоставляем комплексные решения для вашего успеха',
  },
  {
    name: 'Портфолио',
    path: '/portfolio',
    show: true,
    title: 'Портфолио: Примеры наших успешных проектов — ' + companyName,
    description:
      'Ознакомьтесь с нашим портфолио, чтобы увидеть примеры наших успешных проектов в области программирования, дизайна и маркетинга',
  },
  {
    name: 'О компании',
    path: '/company',
    show: true,
    title: 'О компании: Надежный партнер в сфере IT услуг — ' + companyName,
    description:
      'Узнайте больше о нашей компании. Мы являемся надежным партнером, предоставляющим высококачественные IT услуги в области программирования, дизайна и маркетинга',
  },
  {
    name: 'Сотрудничество',
    path: '/cooperation',
    show: true,
    title: 'Сотрудничество: Как начать работу с нами — ' + companyName,
    description:
      'Одно из ключевых правил нашей компании — это быть открытыми ко всему новому. Мы приветствуем новые знакомства и всегда готовы рассмотреть возможность делового сотрудничества',
  },
  {
    name: 'Статьи',
    path: '/articles',
    title: 'Статьи: Инсайты и экспертные мнения от наших специалистов — ' + companyName,
    show: true,
    description:
      'Читайте наши статьи, чтобы получить ценные инсайты и экспертные мнения от наших специалистов в области программирования, дизайна и маркетинга',
    subMenu: [
      {
        name: 'Реклама',
        path: '/articles/reklama',
        title: 'Реклама | Статьи — ' + companyName,
        description: '',
      },
      {
        name: 'Бизнес',
        path: '/articles/business',
        title: 'Бизнес | Статьи — ' + companyName,
        description: '',
      },
      {
        name: 'Дизайн',
        path: '/articles/design',
        title: 'Дизайн | Статьи — ' + companyName,
        description: '',
      },
      {
        name: 'Разработка',
        path: '/articles/develop',
        title: 'Разработка | Статьи — ' + companyName,
        description: '',
      },
      {
        name: 'Продвижение',
        path: '/articles/promotion',
        title: 'Продвижение | Статьи — ' + companyName,
        description: '',
      },
    ],
  },
  {
    name: 'Контакты',
    path: '/contacts',
    title: 'Контакты: Обратная связь и консультации — ' + companyName,
    show: true,
    description:
      'Свяжитесь с нами для консультации, вопросов или запроса предложения. Мы готовы помочь вам в решении ваших задач. Контактный телефон компании — 8 (995) 011-77-05',
  },
  {
    name: 'Политика конфиденциальности',
    path: '/policy',
    // В данном разделе Вы можете ознакомиться с действующей Политикой конфиденциальности
    title: 'Политика конфиденциальности — ' + companyName,
    show: false,
    description:
      'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности',
  },
];

export const menuServices = [
  {
    name: 'Веб-разработка',
    path: '/services/web',
    title: 'Веб-разработка | Услуги — ' + companyName,
    description:
      'Наши эксперты по Веб-разработке предоставляют высококачественные услуги разработки сайтов. Решения на заказ для ваших бизнес-потребностей',
    subMenu: [
      {
        name: 'Электронная торговля',
        path: '/services/web/e-commerce',
        title: 'Электронная торговля | Веб-разработка | Услуги — ' + companyName,
        description: '',
      },
      {
        name: 'Бизнес сайты',
        path: '/services/web/business',
        title: 'Бизнес сайты | Веб-разработка | Услуги — ' + companyName,
        description: '',
      },
      {
        name: 'Высоконагруженные проекты',
        path: '/services/web/highload',
        title: 'Высоконагруженные проекты | Веб-разработка | Услуги — ' + companyName,
        description: '',
      },
      {
        name: 'B2B порталы',
        path: '/services/web/b2b',
        title: 'B2B порталы | Веб-разработка | Услуги — ' + companyName,
        description: '',
      },
    ],
  },
  {
    name: 'Мобильная разработка',
    path: '/services/mobile',
    title: 'Мобильная разработка | Услуги — ' + companyName,
    description:
      'Наши эксперты по Мобильной разработке предоставляют высококачественные услуги разработки приложений. Решения на заказ для ваших бизнес-потребностей',
    subMenu: [
      {
        name: 'Нативная разработка',
        path: '/services/mobile/native',
        title: 'Нативная разработка | Мобильная разработка | Услуги — ' + companyName,
        description: '',
      },
      {
        name: 'Кроссплатформенная разработка',
        path: '/services/mobile/cross-platform',
        title: 'Кроссплатформенная разработка | Мобильная разработка | Услуги — ' + companyName,
        description: '',
      },
    ],
  },
  {
    name: 'Маркетинг',
    path: '/services/marketing',
    title: 'Маркетинг | Услуги — ' + companyName,
    description:
      'Наши маркетологи предоставляют результативные стратегии маркетинга для продвижения вашего бизнеса. Увеличьте видимость и привлекайте новых клиентов',
    subMenu: [],
  },
  {
    name: 'Дизайн',
    path: '/services/design',
    title: 'Дизайн | Услуги — ' + companyName,
    description:
      'Мы предлагаем уникальные услуги дизайна, создавая привлекательные и современные визуальные решения для вашего бренда. Дизайн, который привлекает внимание и впечатляет',
    subMenu: [],
  },
  {
    name: 'IT Экспертиза',
    path: '/services/expertise',
    title: 'IT Экспертиза | Услуги — ' + companyName,
    description:
      'Наши IT-эксперты предоставляют высококачественные услуги IT-анализа и консультаций. Обеспечьте надежную работу вашей информационной инфраструктуры с нашей помощью',
    subMenu: [],
  },
];

// Pattern

// export const inputPattern = {
//   name: '[^A-Za-zА-Яа-яЁё0-9\-_. s]+',
//   tel: '(^8|7|\+7)\s?[\(]{0,1}[0-9]{3}[\)]{0,1}\s?\d{3}[\-\s?]{0,1}\d{2}[\-\s?]{0,1}\d{2}',
//   email: '(.{1,})@(.{1,}).([A-z]{2,8})',
// };
